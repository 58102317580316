<template>
	<div class="lawPhase">
		<table border="1" cellspacing="0">
			<tr v-for="(item,index) in length" :key="index">
				<td>
					<div class="img">
						<a :href="imgList[index*4].picture_url" target="_blank">
							<img :src="imgList[index*4].picture_url" alt />
						</a>
					</div>
				</td>
				<td>
					<div class="img" v-if="imgList[index*4+1]">
						<a :href="imgList[index*4+1].picture_url" target="_blank">
							<img :src="imgList[index*4+1].picture_url" alt />
						</a>
					</div>
				</td>
				<td>

					<div class="img" v-if="imgList[index*4+2]">
						<a :href="imgList[index*4+2].picture_url" target="_blank">
							<img :src="imgList[index*4+2].picture_url" alt />
						</a>
					</div>
				</td>
				<td>
					<div class="img" v-if="imgList[index*4+3]">
						<a :href="imgList[index*4+3].picture_url" target="_blank">
							<img :src="imgList[index*4+3].picture_url" alt />
						</a>
					</div>
				</td>
			</tr>
		</table>

		<!-- 分页 -->
		<div class="splitPage flex-center">
			<el-pagination background @current-change="handleCurrentChange" :page-size="page.per_page"
				layout=" prev, pager, next" :pager-count="3" :total="page.total" :prev-text="$t('preText')"
				:next-text="$t('nextText')"></el-pagination>
		</div>
		<!-- 图片预览 -->
		<!-- <el-image  :src="url" :preview-src-list="srcList"></el-image> -->
	</div>
</template>

<script>
	import {
		getCateList,
		getPicture
	} from "../../api/apis";
	export default {
		name: "",

		data() {
			return {
				cateId: "",
				page: {
					total: 0,
					per_page: 20,
					current_page: 1,
					last_page: 1
				},
				length: 0,
				imgList: [],
				// srcList:[],
				// url:''//预览的图片
			};
		},
		methods: {
			getLength(arr) {
				return Math.ceil(arr.length / 4);
			},
			// 请求页面
			getPicList() {
				let page = {
					page: this.page.current_page,
					limit: this.page.per_page
				};
				getPicture(this.cateId, page).then(res => {
					this.imgList = res.data || [{}];
					this.length = this.getLength(this.imgList);
					// this.srcList = this.imgList.map(item => item.picture_url);
					this.page.total = res.total;
					this.page.per_page = res.per_page;
					this.page.current_page = res.current_page;
					this.page.last_page = res.last_page;
				});
			},
			//初始化数据
			// initData(){},
			//页面改变
			handleCurrentChange(e) {
				this.page.current_page = e;
				this.getPicList();
			},
			// showPreImg(index){
			//     this.url = this.srcList[index];
			// },
		},
		created() {
			getCateList().then(res => {
				this.cateId = res[0][1].id;
				this.getPicList();
			});
		}
	};
</script>

<style lang="stylus" scoped>
	.lawPhase {
		width: 760px;
		padding: 20px;
		margin: 20px;
		// border: 1px solid #ACACAC;
		box-sizing: border-box;
	}

	table,
	td,
	tr {
		// border: 1px solid #ACACAC;
		// border:none;
	}

	table {
		width: 100%;
		border: 1px solid #ACACAC;

		td {
			padding: 5px 15px;
			width: 25%;
			box-sizing: border-box;

			img {
				width: 140px;
				// height 103px;
			}

			vertical-align: center;
		}
	}

	.splitPage {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.img {
		overflow: hidden;
	}

	img {
		transition: all 1s;
	}

	img:hover {
		transform: scale(1.1);
		transition: all 1s;
	}
</style>